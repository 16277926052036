<template>
  <v-app>
    <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size:30px">
          センサグループ管理
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
        
          <!-- 画面左部ツリー -->
          <v-col cols="5">
            <v-text-field
              v-model="treeSearch"
              label="Search"
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              style="margin:8px 38px"
            ></v-text-field>
            <v-treeview
              activatable
              :items="items"
              :active.sync="active"
              :search="treeSearch"
              :filter="filter"
            >
            </v-treeview>
          </v-col>
          <!-- 画面左部ツリー -->
          
          <v-divider vertical></v-divider>
          
          <!-- 画面右部 -->
          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <!--未選択-->
              <div
                v-if="!selected"
                class="text-h6 text--lighten-1 font-weight-light"
                style="align-self: top;"
              >
                センサグループを選択して下さい。
              </div>
              <!--選択中-->
              <v-card
                v-else
                :key="selected.id"
                class="pt-6 mx-auto"
                flat
                width="95%"
              >
                <v-text-field v-if="selected.id === 0" v-model="sensorgroupid" label="センサグループID" :rules="itemRules">
                </v-text-field>
                <v-text-field v-else v-model="sensorgroupid" label="センサグループID" disabled>
                </v-text-field>
                <v-text-field v-model="sensorgroup_name" label="センサグループ名称" :rules="itemRules">
                </v-text-field>
                <v-switch v-model="published" :label="`public画面表示：${publishedString}`" @change="changePublished(published)">
                </v-switch>
                <v-list-group v-if ="selected.id !== 0" no-action append-icon="mdi-plus">
                  <template v-slot:activator>
                    <v-list-item-content>
                      センサ一覧
                    </v-list-item-content>
                  </template>
                  <v-card-subtitle>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-subtitle>
                  <v-data-table dense
                    :headers="sensorGroupHeaders"
                    :items="sensorGroup"
                    :search="search"
                  ></v-data-table>
                </v-list-group>
                <!--ボタン部-->
                <v-row class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn small v-bind:disabled="isSet" v-on:click.stop="setting()" color="primary" disable style="font-size:16px">設定</v-btn>
                </v-row>
              </v-card>
              
            </v-scroll-y-transition>
          </v-col>

        </v-row>
      </v-card>
      <v-row class="pa-4">
      </v-row>
    </v-form>
    </v-container>
  </v-app>
</template>
<script>
  
  export default {
    data: () => ({
      //表示・編集用ユーザ情報
      id:null,
      sensorgroupid:null,
      sensorgroup_name:null,
      published:false,
      publishedString:"公開",
      
      active: [],
      items: [],
      
      isSet:true,
      
      hosturl: process.env.VUE_APP_APIURL,
      
      dialogText:null,
      
      success:false,
      error:false,
      
      valid: true,

      sensorGroupHeaders:[
          { text:'シリアル番号', sortable: false, value:'dev_id' },
          { text:'ロケーション名', sortable: false, value:'dev_name' },
      ],
      sensorGroup:[],
      sensorGroupList:{},
      search: '',

      treeSearch: null,

    }),
    computed: {
      selected () {
        if (!this.active.length) return undefined

        const activeid = this.active[0]
        var group = this.items.find(item => item.id === activeid)
        
        this.setdata(group);
        
        return group
      },
      itemRules() {
        return [
          v => !!v || '必須項目です。'
        ]
      },
      filter(){
        return (item, search, textKey) => this.filterNodes(item, search, textKey)
      }
    },
    methods: {
      //センサグループ情報取得
      getSensorGroup:function(callback){
        //画面左表示用
        var newItems = new Array();
        var apiurl = this.hosturl + "/sensor_group"
        var cnt = 1;
        
        this.$axios.get(apiurl).then(res => {
          console.log(res)
          if(res['data']){
            res['data'].forEach(function ( data ) {
              data['id'] = data['sensorgroupid'];
              //data['name'] = data['sensorgroup_name'];
              data['name'] = data['sensorgroupid'];
              newItems.push(data)
              cnt = cnt + 1;
            });
            
            newItems.sort(function(a, b){
              if(a.name > b.name){
                return 1;
              } else {
                return -1;
              }
            });
            
            newItems.unshift({
              id:0,
              name:'新規作成',
              sensorgroupid:null,
              sensorgroup_name:null,
              published:false
            });
          } else {
            newItems.push({
              id:0,
              name:'新規作成',
              sensorgroupid:null,
              sensorgroup_name:null,
              published:false
            });
          }
          this.items = newItems;
          
          //選択状態初期化
          this.active = [];
          callback(this.items);
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサグループの取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });

      },
      //センサ情報表示
      setdata: function(group){
        this.id = group.id
        this.sensorgroupid = group.sensorgroupid
        this.sensorgroup_name = group.sensorgroup_name
        this.published = group.published
        this.sensorGroup = this.sensorGroupList[group.sensorgroupid]
      },
      //センサ情報設定
      setting: function(){
        if(this.id === 0){
          for(var x = 0; x < this.items.length; x++){
            if( this.sensorgroupid === this.items[x].sensorgroupid){
              this.dialogText = '同じグループ番号は登録できません'
              this.success = false;
              this.error = true;
              this.returnTop('smooth');
              return
            }
          }
        }
        
        var apiurl = this.hosturl + "/sensor_group/" + this.sensorgroupid
        //PUT用パラメータ
        var param = {
          "sensorgroup_name": this.sensorgroup_name,
          "published": this.published
        }
        this.active = []; //新規登録時にエラーメッセージが意図しないタイミングで表示される事象の対応
        this.$axios.put(apiurl, param).then(res => {
          console.log(res)
          this.getSensorGroup(this.getSensorInformation);
          this.dialogText = 'センサグループ情報の設定が完了しました。';
          this.success = true;
          this.error = false;
          this.returnTop('auto');
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサグループ情報の設定に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      // センサグループに該当するセンサ情報の取得
      getSensorInformation:function(items){
        for(var group = 1; group < items.length; group++){
          var groupId = items[group].sensorgroupid;
          this.getSensorName(groupId);
        }
      },
      getSensorName:function(groupId){
        var apiurl = this.hosturl + "/sensors/" + "?sensorgroupid=" + groupId
        var devList = new Array()
        this.$axios.get(apiurl, null).then(res => {
          for(var i = 0; i < res.data.length; i++){
            var removeDevId = "";
            if(res.data[i].dev_id.indexOf('evs_') != -1){
              removeDevId = res.data[i].dev_id.replace('evs_','');
            }
            else{
              removeDevId = res.data[i].dev_id;
            }
            if(res.data[i].enabled){
              var devInfo = {
                dev_id:removeDevId,
                dev_name:res.data[i].dev_name,
              }
              this.$set(devList, i, devInfo);
            }
          }
          devList.sort(function(a, b){
            if(a.dev_name < b.dev_name){
              return -1;
            } else {
              return 1;
            }
          });
          this.sensorGroupList[groupId] = devList;
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサ情報の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
      changePublished: function(published){
        if (published){
          this.publishedString ="公開"
        }
        else{
          this.publishedString ="非公開"
        }
      },
      filterNodes: function(item, search, textKey){
        this.active = [];
        if(item[textKey].indexOf(search) > -1){
          return true
        } else {
          if(item['sensorgroup_name'] && item['sensorgroup_name'].indexOf(search) > -1){
            return true
          } else {
            return false
          }
        }
      },
    },
    mounted() {
      this.getSensorGroup(this.getSensorInformation);
    },
    watch: {
      //設定ボタン
      sensorgroupid: function (newValue) {
        if(newValue && this.sensorgroup_name){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      sensorgroup_name: function (newValue) {
        if(newValue && this.sensorgroupid){
          this.isSet = false;
        } else {
          this.isSet = true;
        }
      },
      published: function (newValue) {
        if (newValue){
          this.publishedString ="公開"
        }
        else{
          this.publishedString ="非公開"
        }
      }
    },
  }
</script>